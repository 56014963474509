<template>
  <ValidationObserver ref="observer">
    <v-form>
      <v-card flat>
        <v-toolbar :color="color" flat dark dense class="mb-4" v-if="title">
          <v-card-title>
            <v-icon left>{{ icon }}</v-icon>
            {{ title }}
          </v-card-title
          >
        </v-toolbar
        >
        <v-row align="center" no-gutters>
          <v-col
              class="px-5 py-1"
              :class="data.flex"
              v-for="(data, index) in formData"
              :key="index"
          >
            <ValidationProvider
                v-slot="{ errors }"
                :name="data.text"
                :rules="data.validate"
            >
              <div v-if="data.options">
                <v-autocomplete
                    :items="loadItems(data)"
                    v-model="item[data.value]"
                    :label="data.text"
                    :required="data.required"
                    :item-text="checkDisplayName(data)"
                    item-value="id"
                    :menu-props="data.showOnTop ? 'top' : 'bottom '"
                    outlined
                    clearable
                    :name="data.text"
                    :hide-selected="!data.multiple"
                    :multiple="data.multiple ? data.multiple : false"
                    :chips="data.chips ? data.chips : false"
                    :deletable-chips="data.chips ? data.chips : false"
                    :filled="data.box ? data.box : false"
                    class="font-weight-medium "
                    :class="data.class"
                    :error-messages="errors"
                >
                </v-autocomplete>
              </div>
              <div v-else-if="data.fileUpload">
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12">
                    <v-file-input
                        @change="selectFile($event, data.value, data.value)"
                        counter
                        loader-height="2"
                        :name="data.text"
                        :loading="uploadingImage"
                        label="Select file "
                        :error-messages="errors"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        outlined
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="data.file">
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" md="12">
                    <v-file-input
                        counter
                        :rules="rules"
                        show-size
                        :accept="data.format"
                        v-model="item[data.value]"
                        outlined
                        loader-height="2"
                        label="Select file "
                        :error-messages="errors"
                        prepend-icon="mdi-paperclip"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="data.treeview">
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" md="12">
                    <h5 class="text-left">{{ data.text }}</h5>
                    <radio-tree
                        v-model="item[data.value]"
                        :items="data.items"
                        :selection-type="data.selectionType"
                        :item-text="checkDisplayName(data)"
                        value-key="id"
                        item-children="all_childrens"
                        :error-messages="errors"
                    ></radio-tree>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="data.password">
                <v-row no-gutters>
                  <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      class="pa-1"
                      v-if="data.generator"
                  >
                    <v-btn
                        text
                        x-large
                        @click.native="generatePassword(data.value)"
                    >Generate Password
                      <v-icon small class="ml-2">mdi-arrow_forward</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-1 d-flex">
                    <v-text-field
                        v-model="item[data.value]"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        autocomplete="new-password"
                        class="font-weight-medium"
                        :name="data.text"
                        outlined
                        :type="showPassword ? 'text' : 'password'"
                        color="success"
                        :error-messages="errors"
                        :required="data.required"
                        :class="data.class"
                        counter
                        @click:append="showPassword = !showPassword"
                        :label="data.text"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="data.date">
                <v-menu
                    :close-on-content-click="false"
                    v-model="datemenu[data.value]"
                    :ref="data.value"
                    :return-value.sync="item[data.value]"
                    transition="scale-transition"
                    :nudge-right="40"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-on="on"
                        :value="formatDate(item[data.value])"
                        :label="data.text"
                        :error-messages="errors"
                        :name="data.text"
                        :required="data.required"
                        :class="data.class"
                        prepend-icon="mdi-calendar"
                        readonly
                        disabled
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      first-day-of-week="1"
                      min="1950-01-01"
                      :ref="'picker_' + data.value"
                      v-model="item[data.value]"
                      no-title
                      @input="$refs[data.value][0].save(item[data.value])"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
              <div v-else-if="data.fullDate">
                <v-text-field
                    :value="
                    formatDate(
                      item[data.value],
                      data.month ? 'MMM' : 'DD.MM.YYYY'
                    )
                  "
                    :label="data.text"
                    :error-messages="errors"
                    :name="data.text"
                    :required="data.required"
                    :class="data.class"
                    prepend-icon="mdi-calendar"
                    readonly
                    disabled
                ></v-text-field>
                <v-date-picker
                    full-width
                    first-day-of-week="1"
                    min="1950-01"
                    :type="data.month ? 'month' : 'date'"
                    color="primary"
                    v-model="item[data.value]"
                    no-title
                >
                </v-date-picker>
              </div>
              <div v-else-if="data.justMonth">
                <v-menu
                    :ref="data.value"
                    v-model="datemenu[data.value]"
                    :close-on-content-click="false"
                    :return-value.sync="datemenu[data.value]"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="formatDate(item[data.value])"
                        :label="data.text"
                        :error-messages="errors"
                        :name="data.text"
                        :required="data.required"
                        :class="data.class"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="item[data.value]"
                      type="month"
                      color="primary"
                      no-title
                      scrollable
                      @input="$refs[data.value][0].save(formatDate(datemenu[data.value]))"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
              <div v-else-if="data.textarea">
                <v-textarea
                    v-model="item[data.value]"
                    :rows="data.rows"
                    :counter="data.counter"
                    outlined
                    :error-messages="errors"
                    :label="data.text"
                    :name="data.text"
                    :required="data.required"
                    class="font-weight-medium"
                    :class="data.class"
                    auto-grow
                ></v-textarea>
              </div>
              <div v-else-if="data.multipleAdd">
                <v-row
                    no-gutters
                    align="center"
                    v-for="(dataValues, index) in data.values"
                    :key="index"
                >
                  <v-col class="pa-3" cols="12" md="3">
                    <v-autocomplete
                        :items="data.multipleAddOptions"
                        v-model="item.job_positions[index]['id']"
                        :label="data.text"
                        :required="data.required"
                        item-text="name"
                        item-value="id"
                        outlined
                        clearable
                        :name="data.text"
                        :hide-selected="data.multiple ? false : true"
                        :multiple="data.multiple ? data.multiple : false"
                        :chips="data.chips ? data.chips : false"
                        :deletable-chips="data.chips ? data.chips : false"
                        :filled="data.box ? data.box : false"
                        class="font-weight-medium "
                        :class="data.class"
                        :error-messages="errors"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col class="pa-3" cols="12" md="3">
                    <v-text-field
                        v-model="item.job_positions[index]['employees_number']"
                        autocomplete="off"
                        :name="data.text2"
                        :error-messages="errors"
                        :label="data.text2"
                        :class="data.class"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pa-3" cols="12" md="3">
                    <v-text-field
                        v-model="item.job_positions[index]['address']"
                        autocomplete="off"
                        :name="data.text3"
                        :label="data.text3"
                        :class="data.class"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn
                        :disabled="item.job_positions.length < 2"
                        @click="removeEventItem(index, 'remove_multi_item')"
                        text
                        class="text-capitalize"
                    >
                      <v-icon>mdi-minus</v-icon>
                      Remove
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                        @click="addEventItem(index, 'add_multi_item')"
                        text
                        class="text-capitalize"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div v-else-if="data.switch">
                <v-switch
                    :true-value="1"
                    :false-value="0"
                    v-model="item[data.value]"
                    autocomplete="off"
                    outlined
                    :name="data.text"
                    :error-messages="errors"
                    :label="data.text"
                    :required="data.required"
                    class="font-weight-medium"
                    :class="data.class"
                ></v-switch>
              </div>
              <div v-else>
                <v-text-field
                    v-model="item[data.value]"
                    autocomplete="off"
                    :name="data.text"
                    :counter="data.counter"
                    :error-messages="errors"
                    outlined
                    :label="data.text"
                    :required="data.required"
                    class="font-weight-medium"
                    :class="data.class"
                ></v-text-field>
              </div>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text outlined @click.native="goBack">
            <v-icon size="20" left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-divider vertical class="mx-6"></v-divider>
          <v-btn
              text
              @click.native="submit"
              dark
              :class="color"
              :loading="loading"
          >
            <v-icon size="20" left>mdi-content-save</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Vue from "vue";
import date from "@/mixins/date";

export default {
  mixins: [date],
  $_veeValidate: {
    validator: "new"
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    RadioTree: () => import("@/components/core/form/RadioTree")
  },
  name: "FormWizard",
  data() {
    return {
      date: null,
      showPassword: false,
      datemenu: {},
      dateMonth: new Date().toISOString().substr(0, 7),
      menu: false,
      rules: [
        value => !value || value.size < 2000000 || 'File size should be less than 2 MB!',
      ]
  };
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters["options/loading"];
      }
    },
    uploadingImage: {
      get() {
        return this.$store.getters["files/loading"];
      }
    },
    textColor() {
      return this.$route.meta.textColor || "primary--text";
    },
    color() {
      return this.$route.meta.color || "primary";
    },
    colorHex() {
      return this.$route.meta.colorHex || "#017d40";
    }
  },
  props: [
    "item",
    "formData",
    "storeAction",
    "returnPreviousScreen",
    "store",
    "goTo",
    "title",
    "namespace",
    "createFormData",
    "icon"
  ],
  methods: {
    checkDisplayName(data) {
      if (data.displayName) return "display_name";
      return "name";
    },
    async selectFile(event, filesInput, folderPath = null) {
      if (typeof event === "object") {
        let formData = new FormData();
        formData.append("file", event, event.name);

        if (folderPath) {
          formData.append("folder_path", folderPath);
        }
        this.$store.dispatch("files/uploadFile", formData).then(response => {
          if (typeof filesInput === "object") {
            filesInput.value = response.data.file_path;
            Vue.set(this.item, "preview_url", response.data.preview_url);
          } else {
            this.item[filesInput] = response.data.file_path;
            Vue.set(this.item, "preview_url", response.data.preview_url);
          }
          Event.$emit("file_upload_complete", response.data.file_path);
          event = null;
        });
      }
    },
    generatePassword(name) {
      let newPassword = Math.random()
          .toString(36)
          .slice(-8);
      Vue.set(this.item, name, newPassword);
      this.showPassword = true;
    },
    loadItems(data) {
      if (Object.prototype.hasOwnProperty.call(data, "filter")) {
        const filter = data.options.filter(
            item => item[data.filter] === this.item[data.condition]
        );
        return filter;
      }
      return data.options;
    },
    makeFormData() {
      const formData = new FormData();
      for (const key of Object.keys(this.item)) {
        if (key == "file") {
          formData.append("file", this.item[key], this.item[key].name);
        } else {
          formData.set(key, this.item[key]);
        }
      }
      return formData;
    },
    addEventItem(index, name) {
      Event.$emit(name, index);
    },
    removeEventItem(index, name) {
      Event.$emit(name, index);
    },
    goBack() {
      if (this.returnPreviousScreen) {
        this.$emit("dialog-cancel", true);
      }
      if (this.goTo) {
        this.$emit("dialog-cancel", true);
        this.$router.push({path: this.goTo});
      } else {
        this.$router.push({name: "list-" + this.namespace});
      }
    },
    closeDialog() {
      this.goBack();
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        let dataForApi = this.item;
        if (this.createFormData) {
          let fd = this.makeFormData();
          dataForApi = fd;
          this.storeAction == "users/updateItem"? dataForApi.append('_method', "PUT"):''
        }

        this.$store.dispatch(this.storeAction, dataForApi).then(() => {
          //fetch new user data
          if (this.storeAction == "users/updateItem") {
            this.$auth.fetch().then(response => {
              this.$auth.user(JSON.parse(JSON.stringify(response.data.user)));
              this.$auth.remember(JSON.stringify(response.data.user));
            });
          }
          this.closeDialog();
        });
      } else {
        this.$store.dispatch("options/setOptionsData", {
          message:
              "There is some form validation errors. Please fix the red form fields!",
          snackbar: true,
          color: "red"
        });
      }
    }
  }
};
</script>
